
/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.buttton-disabled {
  opacity: 0.6;
  cursor: not-allowed;
  color: #b4afaf;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-flex;
  height: 35px;
  padding: 0;
  background: #2c2c2c;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  font-family: sans-serif;
  font-size: 16px;
}

.box {
  --border-size: 0.15rem;
  border: var(--border-size) solid transparent;

  /* Paint an image in the border */
  border-image: conic-gradient(
      from var(--angle),
      red 0deg 90deg,
      rgb(173, 40, 40) 90deg 180deg,
      rgb(189, 50, 50) 180deg 270deg,
      rgb(228, 74, 74) 270deg 360deg
    )
    1 stretch;
}

@supports (background: paint(houdini)) {
  @property --opacity {
    syntax: "<number>";
    initial-value: 0.5;
    inherits: false;
  }

  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }

  @keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  .box {
    animation: rotate 4s linear infinite, opacityChange 3s infinite alternate;
  }
}

.pc-controlbar-modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.pc-controlbar-modal-content {
    border-radius: 5px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fefefe;
  width: 90%; /* Adjust the width as needed */
  max-width: 350px; /* Set a maximum width */
  animation-name: slideIn;
  animation-duration: 0.4s;
}

/* Add Animation */
@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Other existing styles */

@media screen and (max-width: 768px) {
  .pc-controlbar-modal-content {
    width: 80%; /* Adjust the width for smaller screens */
  }
}

/* Add more media queries as needed for different screen sizes */
