.navbar-custom {
  margin-top: 3% !important;
  font-weight: 100 !important;
  text-transform: uppercase;
  letter-spacing: 3px !important;
  color: #727272 !important;
}

.navbar-custom-margin{
  margin-bottom: 2% !important;
}