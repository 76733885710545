.custom-error {
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: center;
  background-color: darkgrey;
  color: white;
  width: 100%;
  font-family: monospace;
  animation: changeBackgroundColor 0.4s ease-in-out;
}


@keyframes changeBackgroundColor {
  0% {
    height: 0px;
  }
  20%{
    height: 5px;
  }
  50%{
    height: 10px;
  }
  80%{
    height: 20px;
  }
  100% {
    height:auto;
  }
}