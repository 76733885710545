.inner-control-window {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.control-button {
  margin-left: 15px;
  display: inline-flex;
  height: 35px;
  padding: 0;
  background: #2c2c2c;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  font-family: sans-serif;
  font-size: 16px;
  cursor: pointer;
}

.button__text,
.button__icon {
  display: inline-flex;
  align-items: center;
  padding: 0 5px;
  color: rgb(255, 255, 255);
  height: 100%;
}

.control-button:hover,
.control-button:hover,
.control-buttont:active,
.control-button:active {
  .button__text,
  .button__icon {
    color: #b4afaf;
  }
}

.button__text {
  font-size: 12px;
}

.button__icon {
  font-size: 1.3em;
  background: rgba(0, 0, 0, 0.08);
}

.hang-call {
  background-color: rgb(214, 69, 69);
}

.button__text-hang-call,
.button__icon-hang-call {
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
  color: rgb(255, 255, 255);
  height: 100%;
}

.control-button:hover,
.control-button:hover,
.control-buttont:active,
.control-button:active {
  .button__text-hang-call,
  .button__icon-hang-call {
    color: #f1d1d1;
  }
}

.button__icon-hang-call {
  font-size: 1.5em;
  padding-right: 10px;
}

.button__text-hang-call {
  font-size: 12px;
}
