.inner-chat-container {
  color: white;
  margin: 5px;
  height: calc(100% - (10px));
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  font-family: monospace;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
}

.chat-svg {
  height: 98%;
  width: 100%;
}
