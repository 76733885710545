.profile-about .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #173b6c;
}

.profile-about .content ul {
  list-style: none;
  padding: 0;
}

.profile-about .content ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.profile-about .content ul strong {
  margin-right: 10px;
}

.profile-about .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #149ddd;
  line-height: 0;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.profile-section-title {
  padding-bottom: 30px;
}

.profile-section-title h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
  color: #173b6c;
}

.profile-section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #149ddd;
  bottom: 0;
  left: 0;
}

.profile-section-title p {
  margin-bottom: 0;
}

.profile-section {
  padding: 30px 0;
}

.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #050d18;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #dce8f8;
  height: 10px;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #149ddd !important;
}

.section-bg {
  background: #f5f8fd;
}
