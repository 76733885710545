.file-upload-container {
    height: 100vh;
    background-color: #f8f9fa;
    border-radius: 10px;
    border: 2px dashed #d1d8e0;
    background-image: linear-gradient(to right top, #f3f3f3, #f7f7f7, #fbfbfb, #ffffff, #ffffff);
}

.file-upload-box {
    border: 2px dashed #d1d8e0;
    background-color: #ffffff;
    border-radius: 10px;
    max-width: 600px;
    margin: auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.upload-btn {
    display: inline-block;
    cursor: pointer;
    background-color: #212529;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
}

.file-input {
    display: none;
}

.file-upload-box h2 {
    font-size: 1.5rem;
    color: #212529;
    font-weight: bold;
}

.file-upload-box p {
    font-size: 0.875rem;
    color: #6c757d;
}

.file-upload-box p.mb-3 {
    color: #212529;
}

.topic-app{
    background-color: #fff;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

.topic-app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* Full height of the viewport */
}

.topic-app .navbar-custom-topic {
    border-radius: 5px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    flex-shrink: 0;
}

.topic-app .content-container {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    /* Prevent scrolling on the main container */
    padding: 0;
}

.topic-app .Row {
    flex-grow: 1;
}

.topic-app .sidebar-custom {
    background-color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    overflow-y: auto;
    border-right: 1px solid #ccc;
    flex-grow: 1;
    /* Make the sidebar take up full height */
}

.topic-app .content-custom {
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    flex-grow: 1;
    /* Make the content area take up full height */
}


::-webkit-scrollbar {
    width: 2px;
    height: 5px;
}



::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: gray;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}


.topic-app .navbar-custom-topic a.selected {
   
    background-color: #e9ecef;
    border-radius: 7px;

}


.topic-app .sidebar-custom li.selected {
    background-color: #e9ecef;
    border-color: #007bff;
   
}

.topic-app .sidebar-custom div.selectedQuestion {
    background: #e9e9e9;
    font-weight: bold;

}