.client-pc-stream-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100vh;
  background-color: #444444;
}

.video-chat-container {
  display: flex;
  flex: 1;
}

.video-container {
  flex: 0.75;
}
.chat-container {
  flex: 0.25;
}

.electron-video-container {
  flex: 0.8;
}
.electron-chat-container {
  flex: 0.2;
}

.control-bar-container {
  background-color: #2c2c2c;
  padding: 10px;
}

