.cust-div:active, 
.custom-div:target,
.custom-div:hover {
    outline: 2px solid rgb(167, 220, 238);
    border-radius: 3%;
}

.custom-inner-div{
    margin-top: 6px;
    margin-left: 6px;
    margin-right: 6px;
}

.custom-div{
    border: 1px solid white;
    cursor: pointer;
}

.window-name{
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}
