.video {
  max-width: 100%;
  position: relative;
  max-height: 100%;
  border: 1px solid white;
  
}

.inner-video-container {
  margin: 2px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; 
}

.stream-svg {
  display: flex;
    justify-content: center;
    align-items: center;
    height: 98%; 
    width: 100%;
    background-color: #2c2c2c;  
}
