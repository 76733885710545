.remove-cursor {
  cursor: default !important;
}

h2 {
  font-weight: 100 !important;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #727272 !important;
}
h5 {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #626262 !important;
  text-align: left;
}

input,
input[type="text"] {
  color: #555;
  width: 100%;
  font-size: 15px;
  height: 52px;
  line-height: normal;
  border: #ddd solid 1px;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin-bottom: -1px;
  padding: 10px 10px 0 40px;
  position: relative;
  z-index: 1;
}
input:focus,
select:focus {
  outline: 0 !important;
  color: #555 !important;
  border-color: #9e9e9e;
  z-index: 2;
}
input:focus ~ .floating-label input:not(:focus):valid ~ .floating-label {
  top: 7px;
  left: 40px;
  font-size: 10px;
  opacity: 1;
  font-weight: 400;
}
input:focus ~ .floating-label,
input:valid ~ .floating-label {
  top: 5px;
  opacity: 1;
  font-size: 10px;
  color: #727272;
}
input:focus ~ .floating-label,
input:not(:focus):valid ~ .floating-label {
  left: 40px;
}
input:focus ~ .input-icon {
  color: #383838;
}
.floating-label {
  position: absolute;
  pointer-events: none;
  left: 40px;
  top: 16px;
  transition: 0.2s ease all;
  color: #777;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  z-index: 3;
  text-transform: uppercase;
}
.input-icon {
  position: absolute;
  top: 18px;
  left: 15px;
  z-index: 3;
  color: #727272;
}

input.outside,
input[type="text"].outside {
  color: #555;
  width: 100%;
  font-size: 15px;
  height: 52px;
  line-height: normal;
  border: #ddd solid 1px;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin-bottom: -1px;
  padding: 10px 10px 10px 40px;
  position: relative;
  z-index: 1;
}
input:focus,
select:focus {
  outline: 0 !important;
  color: #555 !important;
  border-color: #9e9e9e;
  z-index: 2;
}
input:focus
  ~ .floating-label-outside
  input:not(:focus):valid
  ~ .floating-label-outside {
  top: 12px;
  left: 40px;
  font-size: 10px;
  opacity: 1;
  font-weight: 400;
}
input:focus ~ .floating-label-outside,
input:valid ~ .floating-label-outside {
  top: -7px;
  opacity: 1;
  font-size: 10px;
  color: #727272;
  background: #fff;
  padding: 0px 5px;
}
input:focus ~ .floating-label-outside,
input:not(:focus):valid ~ .floating-label-outside {
  left: 35px;
}
.floating-label-outside {
  position: absolute;
  pointer-events: none;
  left: 40px;
  top: 16px;
  transition: 0.2s ease all;
  color: #777;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  z-index: 3;
}
.input-icon-outside {
  position: absolute;
  top: 18px;
  left: 15px;
  z-index: 3;
  color: #727272;
}

.custom-input:active {
  color: #e2c2fd;
  background: azure;
  transition: none;
}

.custom-network-error:hover {
  background: none !important;
}

.credential-custom-span {
  font-size: large;
  margin-left: 20px;
  margin-right: 20px;
  font-family: Monospace;
  width:70%;
}

/* @media screen and (min-width: 1001px) {
  .credential-custom-span {
    font-size: 16px;
    margin-left: 50px;
  }
}

@media screen and (max-width: 1000px) {
  .credential-custom-span {
    font-size: 13px;
    margin-left: 0px;
  }
} */
